import { css } from 'styled-components';

const sizes = {
    phone: 640,
    tablet: 768,
    desktop: 992,
    large: 1200,
};

export const min = (width, ...args) => css`
  @media (min-width: ${width / 16}em) {
    ${ css(...args) }
  }
`;

export const max = (width, ...args) => css`
  @media (max-width: ${width / 16}em) {
    ${ css(...args) }
  }
`;

export const between = (min, max, ...args) => css`
  @media (min-width: ${min/16}em) and (max-width: ${max / 16}em) {
    ${ css(...args) }
  }
`;

// Bigger phones
export const sm = (...args) => min(sizes.phone, ...args);

// Tablets
export const md = (...args) => min(sizes.tablet, ...args);

// Desktops, tablets landscape
export const lg = (...args) => min(sizes.desktop, ...args);

// Large desktops
export const xl = (...args) => min(sizes.large, ...args);


// Only phone & bigger phones
export const smMax = (...args) => max(sizes.phone - 1, ...args);

// Only table in portrait
export const mdMax = (...args) => max(sizes.tablet - 1, ...args);

// Only desktop small or tablet landscape
export const lgMax = (...args) => max(sizes.desktop - 1, ...args);

// Only large desktops
export const xlMax = (...args) => max(sizes.large - 1, ...args);


// Only phones
export const smOnly = (...args) => max(sizes.phone - 1, ...args)

// Only tablets in portrait
export const mdOnly = (...args) => between(sizes.tablet, sizes.desktop - 1, ...args);

// Only small desktop and tablets in landscape
export const lgOnly = (...args) => between(sizes.desktop, sizes.large - 1, ...args);

// Only large desktops
export const xlOnly = (...args) => lg(...args);
