import React from 'react';
import Logo from '../svgs/logo.svg';
import Claim from '../svgs/claim.svg';
import Narrow from './Narrow';
import styled from 'styled-components';

const HeaderWrapper = styled.div`
  background: white;
`;

const StyledHeader = styled.header`
  position: relative;
  padding-top: 0.25em;

  &::after {
    content: ' ';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 3.2em;
    background: ${ props => props.theme.grey };
  }
`;

const StyledNarrow = styled(Narrow)`
  position: relative;
  z-index: 100;
`;

const LogoWrapper = styled.div`
  display: inline-block;
  position: relative;
  top: 0.7em;

  &::before,
  &::after,
  > span::after {
    z-index: -1;
    background: white;
    content: ' ';
    position: absolute;
    top: 0;
  }

  &::before,
  &::after {
    width: 1em;
    bottom: 0;
  }

  &::before {
    left: 1em;
    transform: skew(26.5deg);
  }

  &::after {
    right: 1em;
    transform: skew(-26.5deg);
  }

  > span {
    display: inline-block;

    &::after {
      width: 3em;
      bottom: 2em;
      left: 50%;
      margin-left: -1.5em;
    }
  }
`;

const StyledText = styled.div`
  position: absolute;
  right: ${ props => props.theme.padding };
  bottom: 0.25em;
  text-align: right;
  color: white;
  font-style: italic;

  h1 {
    font-size: 1.65em;
    line-height: 1.2em;
    font-weight: bold;

    span {
      text-transform: uppercase;
    }
  }

  p {
    font-size: 0.75em;
    line-height: 1.2em;
  }
`;

const StyledClaim = styled(Narrow)`
  padding-top: 1.5em;
`;

const Header = () => (
  <HeaderWrapper>
    <StyledHeader>
      <StyledNarrow>
        <LogoWrapper>
          <span>
            <Logo />
          </span>
        </LogoWrapper>
        <StyledText>
          <h1>Sorin <span>Valcan</span></h1>
          <p>Mediendesign & Medientechnik</p>
        </StyledText>
      </StyledNarrow>
    </StyledHeader>
    <StyledClaim>
      <Claim />
    </StyledClaim>
  </HeaderWrapper>
)

export default Header
