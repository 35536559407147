import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Header from './Header';
import * as media from './../lib/media-queries';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import styledReset from 'styled-reset';
import BackgroundV from '../svgs/background-v.svg';
import FontAwesome from './FontAwesome';
import Footer from './Footer';

const theme = {
  black: 'rgb(75,85,90)',
  grey: 'rgb(75,85,90)',
  green: 'rgb(145,180,10)',
  blue: 'rgb(0, 145,225)',
  maxWidth: '1200px',
  padding: '20px',
  media: { ...media },
}

const GlobalStyle = createGlobalStyle`
  ${styledReset}

  html {
    box-sizing: border-box;
    font-size: 10px;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    padding: 0;
    margin: 0;
    font-size: 1.6rem;
    line-height: 1.4em;
    font-family: 'Montserrat', sans-serif;
    color: ${ theme.black };
  }

  html, body {
    background-color: ${ theme.grey };
  }

  a {
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    margin: 0;
  }
`;

const Main = styled.main`
  position: relative;
  overflow: hidden;
  background: white;
  padding-bottom: 3em;
`;

const StyledBackgroundV = styled.div`
  position: absolute;
  top: 30%;
  left: 0;
  transform: translate(-40px, -50%);
  z-index: -1;

  ${ media.md`
    top: 50%;
    left: auto;
    right: 0;
    transform: translate(40px, -50%);
  `}
`;

const Layout = ({ children }) => (
  <ThemeProvider theme={ theme }>
    <>
      <GlobalStyle />
      <FontAwesome />
      <Header />
      <Main>
        {children}
        <StyledBackgroundV>
          <BackgroundV />
        </StyledBackgroundV>
      </Main>
      <Footer />
    </>
  </ThemeProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
