import styled from 'styled-components';
import FullWidth from './FullWidth';

const Narrow = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  padding: 0 ${props => props.theme.padding};

  & & {
    padding-left: 0;
    padding-right: 0;
  }

  ${FullWidth} & {
    padding-left: ${props => props.theme.padding};
    padding-right: ${props => props.theme.padding};
  }
`;

export default Narrow;
