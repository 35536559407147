import  { createGlobalStyle } from 'styled-components';
import { library, config } from '@fortawesome/fontawesome-svg-core';
import FontAwesomeCSS from '@fortawesome/fontawesome-svg-core/styles.css';

import {
  faPhone,
  faComments,
} from '@fortawesome/free-solid-svg-icons';

config.autoAddCss = false;

library.add(
  faPhone,
  faComments,
);

const FontAwesomeGlobalStyle = createGlobalStyle`
  ${ FontAwesomeCSS }
`;

export default FontAwesomeGlobalStyle;
