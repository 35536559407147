import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.footer`
  background-color: ${ props => props.theme.grey };
  padding: 2em 0;
  text-align: center;
  color: white;

  ${ props => props.theme.media.md`
    br {
      display: none;
    }

    a {
      font-size: 0.8em;
    }
  `};
`;

const Footer = () => (
  <StyledFooter>
    Impressum: <br />
    Sorin Valcan, A-1100 Wien, Raxstraße 2/2/5
    <p>
      <a href="javascript:gaOptout();">
        Google Analytics deaktivieren
      </a>
    </p>
  </StyledFooter>
);

export default Footer
